<template>
  <div class="card-custom" v-if="ready">
    <b-card class="mt-3">
      <div class="">
        <h4 class="card-title d-inline">Commission:</h4>
      </div>
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(periode)="data">
          Du {{formatDate(data.item.debut)}} au {{formatDate(data.item.fin)}}
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.SouscriptionFactureStatus, data.value).color">{{$store.api.getParam(params.SouscriptionFactureStatus, data.value).titre}}</b-button>
        </template>
        <template #cell(montant_ht)="data">
          {{data.value.toFixed(2)}}€
        </template>
        <template #cell(docs)="data">
          <div v-if="data.item.status_id > 1">
            <b-button v-if="data.value.preuve != null" size="sm" variant="success" v-b-tooltip.hover title="Preuve de virement">P</b-button>
            <b-button v-if="data.value.facture === null" size="sm" class="ms-2" variant="primary" v-b-tooltip.hover title="Votre facture" @click="$refs.uploadDoc.show(); doc_id = data.item.id; doc_key = 'facture'">F</b-button>
            <b-button v-else size="sm" class="ms-2" variant="success" v-b-tooltip.hover title="Votre facture" :href="data.value.facture" target="_blank">F</b-button>
          </div>
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
    <b-modal id="uploadDoc" ref="uploadDoc" title="Uploader votre document">
      <b-form-group label="Document:">
        <b-input-group class="mt-3">
          <input type="file" placeholder="Choisissez votre document" @change="handleFile" class="form-control"/>
        </b-input-group>
      </b-form-group>
    </b-modal>
    <b-modal size="lg" ref="welcome" hide-footer>
      <template #modal-title>
        Bienvenue sur votre espace partenaire Pautions !
      </template>
      <div class="d-block">
        <p>Votre espace est désormais activé et vous allez pouvoir inviter vos clients, locataires comme propriétaires, à souscrire à l'assurance Zéro Dépôt qui supprime le dépôt de garantie et les litiges liés à sa restitution. </p>
        <p>Afin de vous guider dans vos premiers pas, nous venons de vous envoyer un mail avec un PDF qui va vous présenter rapidement votre espace. N'hésitez pas à contacter notre équipe si besoin, nous sommes là pour répondre à toutes vos questions. </p>
        <p>Nous sommes heureux de vous compter parmi nos partenaires Pautions.</p>
        <p>A très vite,</p>
        <p>L'équipe Pautions.</p>
      </div>
      <b-button variant="outline-primary" class="mt-3" block @click="$refs.welcome.hide()">Fermer</b-button>
    </b-modal>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  computed:{
  },
  components: {
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    params:{
      CommissionType:[]
    },
    fields: [
      {
        key: 'periode',
        label: 'Periode mensuelle',
        sortable:true,
      },
      {
        key: 'nb_inscrit',
        label: "Nombre d'inscription",
        sortable:false,
      },
      {
        key: 'nb_souscription',
        label: "Nombre de souscription",
        sortable:false,
      },
      {
        key: 'montant_ht',
        label: "Montant a payer",
        sortable:false,
      },
      {
        key: 'date_paiement',
        label: "Date de paiement",
        sortable:false,
      },
      {
        key: 'status_id',
        label: "Statut paiement",
        sortable:false,
      },
      {
        key: 'docs',
        label: "Documents",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
    },
    sort:{
      key: 'id',
      value: 'DESC'
    },
    filters:{
    },
    doc_key:null,
    doc_id:null
  }),
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/partenaire/facture', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
    handleFile(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name,
          'doc_id':this.doc_id,
          'doc_key':this.doc_key
        }
        this.$store.api.ajax('/partenaire/doc', tmp, (res) => {
          if(res.status == true){
            this.doc_id = null;
            this.doc_key = null;
            this.$refs.uploadDoc.hide();
            this.datatableInit();
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    }
  },
  beforeMount() {
    this.$store.api.ajax('/partenaire/params', null, (res) => {
      if(res.status === true){
        res.data.SouscriptionFactureStatus.forEach((item) => {
          item.label = item.titre;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    if(this.$route.query.first === "true"){
      setTimeout(() => {
        console.log(this.$refs);
        this.$refs.welcome.show();
      }, 500)
    }
    this.datatableInit();
  },
}
</script>
<style>
</style>
